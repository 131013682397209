/* Copyright (C) Startuplab. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Alberto Hernandez <juan@startuplab.mx>
 * March 2022
 */

// Regular imports
import React, { useState } from 'react';
import { Pressable, Flex, Center, Text, Box, Button, Image } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// TODO
import VendeenMarker from "../../img/map_marker.png";

const VMarker = ({ id, text, lat, lng, onClick }) => {
    const [messageVisible, setmessageVisible] = useState(false);

    const openInMaps = () => {
        window.open(`https://maps.google.com/?q=${lat},${lng}`, '_blank');
    }
    const renderPopUp = () => {
        if (messageVisible) {
            return (
                <Box p="3" mb="2" style={{ width: "180px", backgroundColor: "rgba(255,255,255,0.9)", borderRadius: "5px" }}>
                    <Flex justifyContent="space-between" direction="row" align="center">
                        <Text fontSize="sm" bold>
                            Punto de entrega
                        </Text>
                        <Text textAlign="right" pl="2" py="3" onPress={() => { setmessageVisible(false) }}>
                            <FontAwesomeIcon icon="fas fa-x" color='#000' />
                        </Text>
                    </Flex>
                    <Center >
                        <Text mb="2" fontSize="xs" noOfLines="2">{text}</Text>
                        <Button _text={{color: "white"}} mb="2" bg="#0c8a7b" _hover={{ bg: "#075249" }} _pressed={{ bg: "#075249" }} _focus={{bg: "#075249"}} onPress={() => { openInMaps() }} endIcon={<FontAwesomeIcon icon="fab fa-google" style={{ fontSize: "15px" }} color='#fff' />}>
                            Ver en
                        </Button>
                    </Center>
                </Box>
            )
        } else return null
    }

    return (
        <Center style={{ transform: messageVisible ? "translate(50%, -100%)" : "translate(-50%, -100%)" }} >
            <Pressable onPress={() => { onClick(id); setmessageVisible(true) }}>
                {renderPopUp()}
                <Image mx="auto" h="12" w="10" rounded="full" resizeMode={"contain"} source={VendeenMarker} />
                {/* <FontAwesomeIcon icon="fas fa-location-dot" style={{fontSize: "30px"}} color='#5839b5'/> */}
            </Pressable>
        </Center>
    )

};



export default VMarker;
