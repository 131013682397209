/*
* Checks if the order form is not empty
*
* @params form Object
*
* @returns Boolean
*/
export function isOrderComplete(order, hasDelivery){
    if(!order.hasOwnProperty("name")){
        return false
    }
    if(hasDelivery) {
        if(!order.hasOwnProperty("address")){
            return false
        }
    }
    // Check if the fields have errors
    if(order.name){
        return false
    }
    if(hasDelivery) {
        if(order.address){
            return false
        }
    }

    return true

}