/* Copyright (C) Startuplab. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Alberto Hernandez <juan@startuplab.mx>, 
 * February 2022
 */

// Regular imports
import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import {
    HStack,
    Box,
    AspectRatio,
    ScrollView,
    Flex,
    Stack,
    Heading,
    Center,
    Image,
    Text,
    Spinner
} from 'native-base';

import VendeenAPI from '../api/api.js';
import { gridArray } from "../utils";
import CompanyLogo from "../img/default_company.png";
// Images
import VendeenLogo from "../img/logo.png";


const LandingView = () => {
    const [companiesList, setCompaniesList] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        VendeenAPI.getPublicCompanies().then((c) => {
            if (c.success) {
                setCompaniesList(c.companies)
                setLoading(false)
            }
        })
    }, [companiesList.length]);

    const renderCompaniesList = (companies) =>{
        if(companies.length > 0){
            return(
                renderGrid(companies)
            )
        }
        return null
    } 

    const renderGrid = (data) => {

        let dataArray = null;
        dataArray = gridArray(1, data)

        if (dataArray !== null && dataArray.length > 0 && dataArray[0].length > 0) {
            return dataArray.map((row, Hkey) => (
                <HStack space={2} key={Hkey}>
                    {row.map((company, Bkey) =>
                    (
                        <Link to={"/"+company._id} style={{ textDecoration: 'none' }} >
                            <Box mb="2" key={Bkey} w="90vw" maxHeight={"160"} rounded="lg" overflow="hidden" backgroundColor="white">
                                <HStack w="100%">
                                    <Box w="140">
                                        <AspectRatio h="100%" ratio={4 / 4}>
                                            <Image h="160" resizeMode="cover" alt="image" w="100%" maxWidth={"140"} source={company.logoUrlThumbnail !== (null && undefined) ? { uri: company.logoUrlThumbnail } : CompanyLogo} />
                                        </AspectRatio>
                                    </Box>
                                    <Flex w="60%" h="160" justifyContent="space-between" direction="row" align="center" wrap='wrap'>
                                        <Stack py="4" px="3" space={2} w="100%" h="100%">
                                            <Heading size="sm" noOfLines="2" h="40%">
                                                {company.name}
                                            </Heading>
                                            <Text fontWeight="400" noOfLines="2" h="45%">
                                                {company.description}
                                            </Text>
                                            <Text fontWeight="600" color="#F2A666" noOfLines="1" h="15%">
                                                {company.phone}
                                            </Text>
                                        </Stack>
                                    </Flex>
                                </HStack>
                            </Box>
                        </Link>
                    )
                )}
                </HStack>
            ));
        // TODO
        // } else return nocompanys();
        } else return null
    }

    return (
        isLoading 
            ?   <HStack justifyContent="center" alignItems="center" h="100vh">
                    <Spinner size="lg" color='#0c8a7b' />
                </HStack>
            :   <ScrollView flex={1} backgroundColor={'#F9F9F9'} safeArea>
                    <Flex px='10' h="2xl">
                        <Box mt="10%">                
                            <Center flex={1} justifyContent="center" alignItems="center" h="100%">
                                <Image size={140} w="50%" m="4" resizeMode={"contain"} source={VendeenLogo} alt="Alternate Text" />
                                {/* <Text fontSize="2xl" color="#101817" bold>Negocios...</Text> */}

                                {renderCompaniesList(companiesList)}
                            </Center>
                        </Box>
                    </Flex>
                </ScrollView>
    )    
};


export default LandingView;
