/* Copyright (C) Startuplab. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Alberto Hernandez <juan@startuplab.mx>, 
 * March 2022
 */

// Regular imports
import React from 'react';
import {
    Center,
    Image,
    Text
} from 'native-base';

// Images
import VendeenLogo from "../img/logo.png";

const PrivacyPolicyView = () => {

    return (
        <Center>
            <Image size={150} w="50%" resizeMode={"contain"} m="auto" source={VendeenLogo} alt="Alternate Text" />
            <Text fontSize="2xl" bold>Politica de privacidad Vende fácil</Text>
            <Text fontSize="md">Te invitamos a que revises nuestro aviso de privacidad de Vende fácil.</Text>
            <Text fontSize="md">Gracias por elegir Vende fácil como tu plataforma para desarrollar tu nueva startup. En Vende fácil queremos ayudarte a generar un proyecto exitoso de emprendimiento.</Text>
            <Text fontSize="md">Por lo anterior la privacidad y seguridad de tus datos personales son y siempre serán de mucha importancia para nosotros. El objetivo de esta pagina web, es explicarte como usamos y almacenamos tus datos personales.</Text>
            <Text fontSize="md">En cumplimiento y apego a lo establecido en ley federal de protección de datos personales en posesión de los particulares, su reglamento, los lineamientos del aviso de privacidad y demás normatividad aplicable, se informa lo siguiente:</Text>
            <Text fontSize="md">Nuestra política de privacidad de datos (en lo sucesivo “política”) proporciona la información esencial con el tratamiento de tus datos personales y la relación con Startups de Alto Impacto, S.A.P.I. de C.V. (en adelante Vende fácil).</Text>
            <Text fontSize="md">Debido a nuestro modelo de negocios, es posible que desarrollemos servicios nuevos o adicionales, en caso de que estas nuevas características produjeran cambios importantes en la manera en que recopilamos o tratamos tus datos personales te contactaremos de manera directa para que conozcas estos cambios.</Text>
            <Text fontSize="md">Datos personales que se recopilan</Text>
            <Text fontSize="md">Datos de Usuario: son datos personales que haz facilitado para que puedas inscribirte en la plataforma de Vende fácil. Dependiendo de la versión en la que se encuentre tu proyecto estos pueden incluir: nombre de usuario, dirección de correo electrónico, domicilio, nombre completo, CURP, fecha de nacimiento, genero y país. De manera opcional puede incluir su perfil de redes sociales y copia de alguna identificación con fotografía.</Text>
            <Text fontSize="md">Datos de Uso: son los datos personales que se recopilan cuando utilizas la plataforma para generar tu proyecto en Vende fácil, en particular:</Text>
            <Text fontSize="md">Información sobre ideas y/o planes de negocio.</Text>
            <Text fontSize="md">Inferencias acerca de las necesidades de tu proyecto de emprendimiento.</Text>
            <Text fontSize="md">Datos técnicos como URL, cookies y direcciones de IP.</Text>
            <Text fontSize="md">Información sobre retroalimentaciones a proyectos.</Text>
            <Text fontSize="md">Participantes y mentores para cada proyecto.</Text>
            <Text fontSize="md">Para qué se usan tus datos personales.</Text>
            <Text fontSize="md">Cuando utilizas la plataforma de Vende fácil e interactúas con otros mentores y emprendedores, aplicamos diversas tecnologías para el tratamiento de tus datos personales, que hemos recopilado. El uso de estos datos de desglosa de la siguiente manera:</Text>
            <Text fontSize="md">Datos de usuario se utilizan:</Text>
            <Text fontSize="md">Para cumplir con la comprobación con algún apoyo o beca que hayas recibido de parte algún organismo publico o institución de educación superior.</Text>
            <Text fontSize="md">Para generar estadística desagregada sobre la efectividad y eficiencia de los programas de emprendimiento e innovación apoyados por la algún gobierno, universidad o entidad privada.</Text>
            <Text fontSize="md">Para evaluar y desarmar nuevas funciones, programas de apoyo, servicios y capacitaciones a la plataforma de Vende fácil.</Text>
            <Text fontSize="md">Con fines de marketing para informar y promocionar sobre eventos de innovación y el ecosistema emprendedor de San Luis Potosí.</Text>
            <Text fontSize="md">Para cumplir con obligaciones legales y requerimientos de las autoridades competentes.</Text>
            <Text fontSize="md">A quien se comparten tus datos personales</Text>
            <Text fontSize="md">Tus datos personales son lo más importante para nosotros, por eso te informamos que nunca venderemos o compartiremos tu información con algún tercero. Sin embargo tu nombre, imagen de perfil y correo electrónico puede ser compartida con otros usuarios de la plataforma Vende fácil.Tus datos personales y de tu proyecto de negocio únicamente se compartirán con los mentores o socios que tu apruebes previamente al momento de invitarlos a participar o darte retroalimentación en tu proyecto.</Text>
            <Text fontSize="md">Transferencia de datos</Text>
            <Text fontSize="md">Los datos personales recabados no serán transferidos a ningún tercero, bajo ninguna circunstancia, excepto cuando alguna autoridad administrativa o jurisdiccional lo requiera, así como a dependencias de administración o impartición de justicia, para lo cual no se requerirá de su consentimiento, en los supuestos previstos por el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</Text>
            <Text fontSize="md">Derechos de acceso, rectificación, cancelación y oposición</Text>
            <Text fontSize="md">Tu como miembro de Vende fácil tienes, en todo momento, el derecho a acceder a los datos personales que son tratados, así como a conocer para qué los utilizamos y las condiciones generales del tratamiento (Acceso). Para hacer uso de este derecho es necesario te comuniques con nosotros por escrito a nuestro domicilio antes mencionado o enviando un correo a Vende fácil @ StartupLab.mx</Text>
            <Text fontSize="md">Asimismo, es tu derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que sea eliminada cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como a oponerse al uso de sus datos personales para fines específicos (Oposición), todo a esto a menos que estemos legalmente facultados u obligados a mantener ciertos datos personales, en particular en situaciones como las siguientes:</Text>
            <Text fontSize="md">Cuando necesitemos conservar datos personales a efectos de nuestras obligaciones legales, fiscales, de auditoría y/o contabilidad, conservaremos los datos personales necesarios durante el periodo exigido por la legislación aplicable.</Text>
            <Text fontSize="md">Cuando sea necesario para nuestros intereses empresariales legítimos, como la prevención de fraudes o la preservación de la seguridad de nuestros usuarios. </Text>
            <Text fontSize="md">Modificaciones de nuestra politica de privacidad.</Text>
            <Text fontSize="md">Es posible que de vez en cuando modifiquemos nuestra política de privacidad, derivado de nuevos productos, servicios o requerimientos legales.Por tal motivo, lo mantendremos informado sobre los cambios efectuados al presente aviso de privacidad, mediante su publicación en el sitio web https://vendefacil.startuplab.mx</Text>
            <Text fontSize="md">Aviso de privacidad integral & Contacto</Text>
            <Text fontSize="md">Los usuarios de Vende fácil podrán conocer el texto del aviso de privacidad integral en el domicilio ubicado en Av. Manuel J. Clouthier #161 Int 301, Col. Tangamanga, San Luis, Potosí, S.L.P., C.P. 78233 - México.</Text>
            <Text fontSize="md">Por medio del presente aviso de privacidad y, en cualquier caso, mediante la navegación en el sitio web y la introducción voluntaria de cualesquiera datos de carácter personal en cualquier sección o formulario del mismo, se entenderá que el Usuario otorga su consentimiento y acepta el tratamiento de sus datos personales, para las finalidades previstas en el presente aviso de privacidad.</Text>
            <Text fontSize="md">Ultima Modificación, 30 de Enero de 2020</Text>
        </Center>
    )    
};


export default PrivacyPolicyView;
