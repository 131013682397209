/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Alberto Hernandez <juan@startuplab.mx>
 * February 2022
 */

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NativeBaseProvider } from 'native-base';
// Import style
import './App.css';

// Import views
import LandingView          from "./views/LandingView";
import PrivacyPolicyView    from "./views/PrivacyPolicyView";
import CompanyView          from "./views/company/CompanyView";

// Icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas, fab, far)



export default function App() {
    return (
        <NativeBaseProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<LandingView/>} />
                    <Route path="/politica-privacidad" element={<PrivacyPolicyView/>} />
                    <Route exact path="/:id" element={<CompanyView/>} />
                </Routes>
            </Router>
        </NativeBaseProvider>
    );
}
