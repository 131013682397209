/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Israel Arellano <israel@startuplab.mx>
 * February 2022
 */

export function gridArray (totalColumns, data) {
    let gridArray = [[]];
    let countColumns = 1;
    
    if(data !== undefined) {
        for (var i = 0; i < data.length; i++) {
            gridArray[gridArray.length - 1].push(data[i]);
            if (countColumns <= totalColumns) {
                countColumns++;
            }
            if (countColumns > totalColumns && i !== data.length - 1) {
                countColumns = 1;
                gridArray.push([]);
            }
        }
    }

    return gridArray;
}