/* Copyright (C) Startuplab. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Alberto Hernandez <juan@startuplab.mx>
 * March 2022
 */

// Regular imports
import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Center, Heading, Text, AlertDialog, Button } from "native-base";

// Custom imports
import VMarker from './VMarker.js';

const VMapModal = ({ markersArray, dialogVisible, hideDialog }) => {

    const [mapCenter, setMapCenter] = useState((markersArray.length > 0) ? markersArray[0] : { lat: 22.14982, lng: -100.97916 });
    const [mapZoom, setMapZoom] = useState(13);

    const onMarkerSelected = (id) => {
        let newCenter = {
            lat: markersArray[id].lat,
            lng: markersArray[id].lng
        }
        setMapCenter(newCenter)
        setMapZoom(mapZoom === 15 ? 16 : 15)
    }

    const renderMarkers = () => {
        return markersArray.map((marker, key) => (
            <VMarker
                id={key}
                lat={marker.lat}
                lng={marker.lng}
                text={marker.text}
                onClick={onMarkerSelected}
            />
        ))
    }

    const renderContent = () => {
        if (markersArray.length > 0) {
            return (
                <AlertDialog isOpen={dialogVisible} onClose={() => hideDialog()}>
                    <AlertDialog.Content>
                        <AlertDialog.CloseButton />
                        <AlertDialog.Header bg="muted.50">Puntos de entrega</AlertDialog.Header>
                        <AlertDialog.Body bg="muted.50">
                            <Center h="350">
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyAmL2-EapfTl9a_caOW8ld42ZP-BpNHeJQ" }}
                                    defaultCenter={markersArray[0]}
                                    center={mapCenter}
                                    zoom={mapZoom}
                                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                >
                                    {renderMarkers()}
                                </GoogleMapReact>
                            </Center>
                        </AlertDialog.Body>
                        <AlertDialog.Footer bg="muted.50">
                            <Button _text={{color: "white"}} bg="#0c8a7b" _hover={{ bg: "#075249" }} _pressed={{ bg: "#075249" }} onPress={() => hideDialog()}>Ok</Button>
                        </AlertDialog.Footer>
                    </AlertDialog.Content>
                </AlertDialog>
            )
        } else {
            return (
                <AlertDialog isOpen={dialogVisible} onClose={() => { hideDialog() }}>
                    <AlertDialog.Content>
                        <AlertDialog.CloseButton />
                        <AlertDialog.Header bg="muted.50">Puntos de entrega</AlertDialog.Header>
                        <AlertDialog.Body bg="muted.50">
                            <Center h="300">
                                <Heading size="md" ml="-1">
                                    Revisa otras opciones de envío
                                </Heading>
                                <Text fontSize="xs" mt="2" p="2" _light={{ color: "#075249" }} _dark={{ color: "cyan.400" }} fontWeight="500" ml="-0.5">
                                    No hay puntos de entrega registrados.
                                </Text>
                            </Center>
                        </AlertDialog.Body>
                        <AlertDialog.Footer bg="muted.50">
                            <Button _text={{color: "white"}} bg="#0c8a7b" _hover={{ bg: "#075249" }} _pressed={{ bg: "#075249" }} _focus={{bg: "#075249"}} onPress={() => { hideDialog() }}>Ok</Button>
                        </AlertDialog.Footer>
                    </AlertDialog.Content>
                </AlertDialog>
            )
        }
    }

    return renderContent()

};



export default VMapModal;
