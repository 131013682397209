/* Copyright (C) Startuplab - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Alberto Hernandez <juan@startuplab.mx> & Israel Arellano <israel@startuplab.mx>
 * January 2022
 */

import React, { useEffect, useState } from 'react';
import { Input, FormControl, Box, Stack, TextArea, Text  } from "native-base";
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import * as validations from '../../utils/validations/input';

const VInput = (props) => {

    const [value, setValue] = useState('');
    const [cleanValue, setCleanValue] = useState('');
    const [has_error, setHasError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
  
    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value, cleanValue])

    const onChangeText = (value) => {
        setValue(value);
        setCleanValue(value);
        props.onChange({
            model: props.model,
            value: value
        });
    }
    
    const shouldValidate = () => {
        return ((!isNil(value) && value.length > 0) || props.required) && props.validationRules
    }

    const validate = () => {
        if (shouldValidate()) {
            //Reads the validation from the validationRules prop and parses it
            let BreakException = { method_failed: null, condition: null, field_id: null }
            let rules = props.validationRules.split('|')
            try {
            rules.forEach(function (rule) {
                let validationFunction = rule.split(':');
                //convert nulls to empty strings
                let tx_value = cleanValue  === null ? '' : cleanValue;
                //calls the validators
                let is_valid = validationFunction.length > 1 ?
                validations[validationFunction[0]](tx_value, validationFunction[1]) :
                validations[validationFunction[0]](tx_value)
                //If the validation does not pass. throws the exception
                if (!is_valid) {
                BreakException = {
                    field_id: props.label.toLowerCase(),
                    method_failed: validationFunction[0],
                    condition: validationFunction.length > 1 ?
                    validationFunction[1] : null
                }
                throw BreakException;
                }
            });
            //TODO: Validate when other type of exception happens
            } catch (exception) {
                setHasError(true);
                setErrorMessage(validations.getMessage(exception))
                
                props.hasError({
                    model:props.model,
                    has_error: true
                })

                return false;
            }
        }
        setHasError(false);
        setErrorMessage('')

        props.hasError({
            model: props.model,
            has_error: false
        })

        return true;
        
    }

    const renderLabel = () => {
        if(props.showLabel) {
            return(
                <FormControl.Label>{props.label}</FormControl.Label>
            )
        } else {
            return null
        }
    }

    const renderError = () => {
        if (has_error) {
            return (
                <FormControl.HelperText>
                    <Text fontSize="xs" color="red.600">
                        { error_message }
                    </Text>
                </FormControl.HelperText>
            );
        } else if(props.renderHelp) {
            return (
                <Text mt="1" fontSize="xs" color="gray.600">{props.helpText}</Text>
            )
        }
    }

    const renderInput = () => {
        if(props.isTextArea) {
            return(
                <TextArea
                    isInvalid={has_error}
                    borderColor="gray.200"
                    _focus={{ borderColor: "fuchsia.400"}}
                    placeholderTextColor="gray.500"
                    size={'md'}
                    h={20}
                    variant="outline"
                    onChangeText={(text) => onChangeText(text)}
                    value={value}
                    placeholder={props.placeholder}
                    keyboardType={props.inputType}
                    secureTextEntry={props.secureTextEntry}
                    maxLength={props.maxLength}
                />
            )
        } else {
            return(
                <Input
                    borderColor="gray.200"
                    _focus={{ borderColor: "fuchsia.400"}}
                    placeholderTextColor="gray.500"
                    size={'md'}
                    variant="outline"
                    onChangeText={(text) => onChangeText(text)}
                    value={value}
                    placeholder={props.placeholder}
                    keyboardType={props.inputType}
                    secureTextEntry={props.secureTextEntry}
                    maxLength={ props.maxLength }
                    onPressIn={() => props.onPress()}
                />
            )
        }
    }

    return (
        <Box mb='2' w="100%" maxWidth="300px">
            <FormControl isInvalid={has_error}>
                <Stack>
                    { renderLabel() }
                    <Box borderRadius="md" shadow="9" bg="muted.50">
                        { renderInput() }
                    </Box>
                    {renderError()}
                </Stack>
            </FormControl>
        </Box>
    );
}

VInput.propTypes = {
    model:                  PropTypes.string,
    showLabel:              PropTypes.bool,
    label:                  PropTypes.string,
    inputStyle:             PropTypes.string,
    inputType:              PropTypes.string,
    placeholder:            PropTypes.string,
    onChange:               PropTypes.func,
    secureTextEntry:        PropTypes.bool,
    maxLength:              PropTypes.number,   
    validationRules:        PropTypes.string,
    value:                  PropTypes.string,
    hasError:               PropTypes.func,
    isTextArea:             PropTypes.bool,
    renderHelp:             PropTypes.bool,
    helpText:               PropTypes.string,
    onPress:                PropTypes.func,  
}

VInput.defaultProps = {
    model: '',
    showLabel: true,
    label: '',
    inputStyle: '',
    inputType: 'default',
    placeholder: '',
    onChange: () => {},
    secureTextEntry: false,
    maxLength: 255,
    validationRules: '',
    value: '',
    hasError: () => {},
    isTextArea: false,
    renderHelp: false,
    helpText: '',
    onPress: () => {},
}
  
export default VInput;