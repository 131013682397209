const axios = require('axios');
// const Session = require("./Session");

// Auth example
// const getAuthHeaders = () =>{
//     return {
//         headers:{
//             'Authorization': `Bearer: ${getAuthToken()}`
//         }
//     }
// }

// const getAuthToken = () =>{
//     return localStorage.getItem('NPAuthToken');
// }

const VendeenAPI = {
    API_URL: process.env.REACT_APP_API_URL,
    getPublicCompany(company_id) {
        return axios.get(`${VendeenAPI.API_URL}/companies/get_public/${company_id}`).then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
    getPublicCompanies() {
        return axios.get(`${VendeenAPI.API_URL}/companies/get_all`).then(res => {
            return res.data;
        })
        .catch(err => {
            return err;
        })
    },
}
module.exports = VendeenAPI;
