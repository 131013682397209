/* Copyright (C) Startuplab. - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Juan Alberto Hernandez <juan@startuplab.mx> & Israel Arellano <israel@startuplab.mx>, 
 * February 2022
 */

// Regular imports
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { ImageBackground } from "react-native";

import VendeenAPI from '../../api/api.js';
import { Center, Pressable, Image, Heading, Stack, HStack, VStack, Text, Box, Divider, Button, Flex, AspectRatio, Spinner, Fab, Modal, Checkbox, FormControl } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Custom imports
import VInput from '../../components/form/VInput';
import VMapModal from '../../components/map/VMapModal.js';
import CompanyLogo from "../../img/default_company.png";
import ProductImage from "../../img/product_image.png";
import { gridArray } from "../../utils"

// Validations
import { isOrderComplete } from "../../utils/validations/form"

const CompanyView = () => {

    let { id } = useParams();
    const [company, setCompany] = useState({});
    const [companyCoordinatesArray, setCompanyCoordinatesArray] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [showLogo, setLogo] = useState(false);
    const [showWp, setWp] = useState(false);
    const [myCart, setCart] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [fields, setFields] = useState({name: '', address: ''});
    const [validFields, setValidFields] = useState({})
    const [homeDelivery, setHomeDelivery] = useState(false);

    useEffect(() => {
        VendeenAPI.getPublicCompany(id).then((c) => {
            if (c.success) {
                let emptyCart = {};
                c.company.products.map(product => (
                    emptyCart[product._id]={
                        quantity: 0,
                        name: product.name
                    }
                ))
                setCart(emptyCart)
                setCompany(c.company);
                let coordinates = [];
                if(c.company.coordinates !== null){
                    coordinates.push({
                        text: c.company.formatted_address,
                        lng: c.company.coordinates[0],
                        lat: c.company.coordinates[1],
                    })
                }
                if(c.company.delivery_coordinates !== null){
                    coordinates.push({
                        text: c.company.delivery_formatted_address,
                        lng: c.company.delivery_coordinates[0],
                        lat: c.company.delivery_coordinates[1],
                    })
                }
                setCompanyCoordinatesArray(coordinates);
                setIsLoading(false);
                if (c.company.logoUrlThumbnail !== null) {
                    setLogo(true)
                }
                if (c.company.wallPaperUrlThumbnail !== null) {
                    setWp(true)
                }
            }
        })
    }, [id]);

    const sendWhatsapp = (phone) => {
        window.open(`https://wa.me/521${phone}`, '_blank');
    }

    const cleanCart = () => {

        let arrayCleaned = {...myCart}

        for (let key in arrayCleaned) {
            arrayCleaned[key].quantity = 0;
        }

        setCart(arrayCleaned)

    }
    
    const askForProducts = () => {
        
        if(isOrderComplete(validFields, homeDelivery)) {
            let encodedString = '';
    
            for (let key in myCart) {
                if(myCart[key].quantity > 0) {
                    encodedString = encodedString.concat(encodeURIComponent(myCart[key].name))
                    encodedString = encodedString.concat('%3A%2A')
                    encodedString = encodedString.concat(encodeURIComponent(myCart[key].quantity))
                    encodedString = encodedString.concat('%2A%0A')
                }
            }
    
            if(homeDelivery) {
                encodedString = encodedString.concat('%0AA%20la%20direcci%C3%B3n%3A%2A')
                encodedString = encodedString.concat(encodeURIComponent(fields.address))
                encodedString = encodedString.concat('%2A')
            }

            window.open(`https://wa.me/521${company.phone}?text=Hola%2C%20mi%20nombre%20es%20%2A${fields.name}%2A%20y%20quisiera%20comprar%20los%20siguientes%20productos%20de%20su%20tienda%3A%2A${company.name}%2A%0A%0A${encodedString}`, '_blank');
            setShowModal(false)
            cleanCart()

        }


    }

    const goToLink = (link) => {
        window.open(link, '_blank');
    }

    const addProduct = (product) => {
        setCart(prevCart => ({
            ...prevCart, 
            [product]: {...prevCart[product], quantity: prevCart[product].quantity+=1}
        }))
    }

    const removeProduct = (product) => {
        if(myCart[product].quantity > 0) {
            setCart(prevCart => ({
                ...prevCart, 
                [product]: {...prevCart[product], quantity: prevCart[product].quantity-=1}
            }))
        }
    }

    const noProducts = () => {
        return (
            <Box mt="4" pb="40">
                <Center>
                    <Text fontSize="xl" textAlign="center" fontWeight="600">
                        Este negocio aún no tiene productos registrados
                    </Text>
                    <Text mt="4">
                        <FontAwesomeIcon icon="fas fa-store" size="2xl" />
                    </Text>
                </Center>
            </Box>
        )
    }

    const renderWebsiteIcon = () => {
        if (company.website !== null) {
            return (
                <Button bg="gray.600" _hover={{ bg: "gray.800" }} _focus={{ bg: "gray.800" }} mr="3" onPress={() => goToLink(company.website)}>
                    <FontAwesomeIcon icon="fas fa-earth-americas" size="xl" color='#fff' />
                </Button>
            )
        } else {
            return null
        }
    }
    const renderFacebookIcon = () => {
        if (company.facebook_link !== null) {
            return (
                <Button bg="blue.600" _hover={{ bg: "blue.800" }} _focus={{ bg: "blue.800" }} mr="3" onPress={() => goToLink(company.facebook_link)}>
                    <FontAwesomeIcon icon="fab fa-facebook" size="xl" color='#fff' />
                </Button>
            )
        } else {
            return null
        }
    }
    const renderInstagramIcon = () => {
        if (company.instagram_link !== null) {
            return (
                <Button bg="secondary.600" _hover={{ bg: "secondary.800" }} _focus={{ bg: "secondary.800" }} mr="3" onPress={() => goToLink(company.instagram_link)}>
                    <FontAwesomeIcon icon="fab fa-instagram" size="xl" color='#fff' />
                </Button>
            )
        } else {
            return null
        }
    }

    const renderHeader = () => {
        if (showWp) {
            return (
                <ImageBackground source={company.wallPaperUrlThumbnail} alt={company.name} resizeMode="cover" style={{ flex: 1, justifyContent: "center" }}>
                    <Box bg="muted.50" w="90%" rounded="md" shadow={9} py="4" px="3" m="4">
                        <VStack>
                            <Box>
                                <Flex justifyContent="space-between" direction="row" align="center" wrap='wrap'>
                                    <Box w="40%">
                                        <Image size={100} rounded="full" alt={company.name} source={showLogo ? { uri: company.logoUrlThumbnail } : CompanyLogo} />
                                    </Box>
                                    <Box w="60%">
                                        <Text fontSize="xl" bold noOfLines="2">
                                            {company.name}
                                        </Text>
                                        <Text fontSize="sm" noOfLines="3">
                                            {company.description}
                                        </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        </VStack>
                    </Box>
                </ImageBackground>
            )
        } else {
            return (
                <Center py="4" backgroundColor="F9F9F9">
                    <Box bg="white" w="90%" rounded="md" shadow={9} py="4" px="3" m="4">
                        <VStack>
                            <Box>
                                <Flex justifyContent="space-between" direction="row" align="center" wrap='wrap'>
                                    <Box w="40%">
                                        <Image size={100} rounded="full" alt={company.name} source={showLogo ? { uri: company.logoUrlThumbnail } : CompanyLogo} />
                                    </Box>
                                    <Box w="60%">
                                        <Text fontSize="xl" bold noOfLines="2">
                                            {company.name}
                                        </Text>
                                        <Text fontSize="sm" noOfLines="3">
                                            {company.description}
                                        </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        </VStack>
                    </Box>
                </Center>
            )
        };
    }

    const renderGrid = (data) => {

        let dataArray = null;
        dataArray = gridArray(1, data)

        if (dataArray !== null && dataArray.length > 0 && dataArray[0].length > 0) {
            return dataArray.map((row, Hkey) => (
                <HStack space={2} key={Hkey}>
                    {row.map((product, Bkey) =>
                    (
                        product.showProduct ?
                        <Box mb="2" key={Bkey} w="100%" rounded="lg" overflow="hidden" bg="white">
                            <HStack w="100%">
                                <Box w="40%">
                                    <AspectRatio h="100%" ratio={4 / 3}>
                                        <Image h="140" resizeMode="cover" alt="image" w="100%" source={product.productUrlThumbnail !== null ? { uri: product.productUrlThumbnail } : ProductImage} />
                                    </AspectRatio>
                                    <Center bg="#0c8a7b" position="absolute" bottom="0" px="3" py="1.5">
                                        <Text color="white" fontWeight="600">
                                            ${product.price}
                                        </Text>
                                    </Center>
                                </Box>
                                <Flex w="60%" h="140" justifyContent="space-between" direction="row" align="center" wrap='wrap'>
                                    <Stack p="4" space={2} w="100%" h="100%">
                                        <Heading size="sm" noOfLines="1" h="30%">
                                            {product.name}
                                        </Heading>
                                        <Text fontWeight="400" noOfLines="2" h="50%">
                                            {product.description}
                                        </Text>
                                        <Box h="20%">
                                            <Stack direction="row" space={3}>
                                                <FontAwesomeIcon icon="fas fa-minus-square" size="xl" color='#0c8a7b' onClick={() => removeProduct(product._id)}/>
                                                <Text>{myCart[product._id].quantity}</Text>
                                                <FontAwesomeIcon icon="fas fa-plus-square" size="xl" color='#0c8a7b' onClick={() => addProduct(product._id)}/> 
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Flex>
                            </HStack>
                        </Box>
                        : null
                    )
                )}
                </HStack>
            ));
        } else return noProducts();
    }

    const renderOrderButton = () => {
        let carHasProducts= Object.keys(myCart).some(el => {
            return myCart[el].quantity !== 0 
        });
        if(carHasProducts) {
            return(
                <Fab p="0" bg="white" position="fixed" size="sm" label = {
                    <Button p="2" _text={{color: "white"}} fontSize="sm" bg="#0c8a7b" _hover={{ bg: "#075249" }} _pressed={{ bg: "#075249" }} _focus={{bg: "#075249"}} onPress={() => setShowModal(true)}>Ordenar</Button>
                }/>
            )
        } else return null;
    }

    const onChange = (res) => {
        setFields(prevFields => ({
            ...prevFields, 
            [res.model]: res.value
        }))
    }

    const validForm = (field) => {
        setValidFields(prevFields => ({
            ...prevFields, 
            [field.model]: field.has_error
        }))
    }

    const renderAddressDelivery = () => {
        if(company.home_delivery) {
            return (
                <Box>
                    <Stack direction="row" alignItems="flex-start" my="3">
                        <Checkbox colorScheme="purple" isChecked={homeDelivery} onChange={() => setHomeDelivery(!homeDelivery)}>
                            <FormControl.Label m="0" ml="2">¿Deseas ordenar a domicilio?</FormControl.Label>
                        </Checkbox>
                    </Stack>
                    { (homeDelivery) ?
                        <VInput
                            label={'Dirección de entrega'}
                            placeholder={'Dirección'}
                            model={'address'}
                            value={fields.address}
                            onChange={(res) => onChange(res)}
                            maxLength={40}
                            validationRules='required'
                            hasError={(field) => validForm(field)}
                            required={true}
                        />
                        : null
                    }
                </Box>
            )
        } else return null;
    }

    const renderModal = () => {
        return(
            <Modal isOpen={showModal} onClose={() => { setShowModal(false) }} style={{height: '100vh', position:'sticky', top: '30px'}}>
                <Modal.Content maxWidth="400px">
                    <Modal.CloseButton />
                    <Modal.Header>Estas a un paso de finalizar tu orden</Modal.Header>
                    <Modal.Body>
                        <VInput
                            label={'Nombre'}
                            placeholder={'Escribe tu nombre'}
                            model={'name'}
                            value={fields.name}
                            onChange={(res) => onChange(res)}
                            maxLength={10}
                            validationRules='required'
                            hasError={(field) => validForm(field)}
                            required={true}
                        />
                        {renderAddressDelivery()}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button.Group space={2}>
                        <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                            setShowModal(false)
                        }}>
                            Cancelar
                        </Button>
                        <Button _text={{color: "white"}} bg="fuchsia.700" _hover={{bg: "fuchsia.900"}} _pressed={{bg: "fuchsia.900"}} _focus={{bg: "fuchsia.900"}} onPress={() => {
                            askForProducts();
                        }}>
                            Finalizar orden
                        </Button>
                    </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        )
    }

    const renderContent = () => {
        if (isLoading) {
            return (
                <HStack justifyContent="center" alignItems="center" h="100vh">
                    <Spinner size="lg" color='#0c8a7b' />
                </HStack>
            )
        } else {
            return (
                <Box bg="muted.50" h="full" backgroundColor={'#F9F9F9'}>
                    {renderHeader()}
                    <Box m="4">

                        <VStack space={1}>
                            <Text fontSize="xl" bold mb="2">Contácto</Text>
                            <Box>
                                <Flex direction="row" align="center" wrap='wrap'>
                                    <Button bg="green.600" _hover={{ bg: "green.800" }} _focus={{ bg: "green.800" }} mr="3" onPress={() => sendWhatsapp(company.phone)}>
                                        <FontAwesomeIcon icon="fab fa-whatsapp" size="xl" color='#fff' />
                                    </Button>
                                    {renderWebsiteIcon()}
                                    {renderFacebookIcon()}
                                    {renderInstagramIcon()}
                                </Flex>
                            </Box>
                        </VStack>

                        <Divider my="4" />

                        <VStack space={1}>
                            <Pressable onPress={() => setDialogVisible(true)}>
                                <Box bg="white" rounded="md" p="4">
                                    <Flex justifyContent="space-between" direction="row" align="center" wrap='wrap'>
                                        <Text w="15%">
                                            <FontAwesomeIcon icon="fas fa-map-marked-alt" size="2xl" />
                                        </Text>
                                        <Box w="75%">
                                            <Text fontSize="lg" bold>
                                                Puntos de entrega
                                            </Text>
                                            <Text fontSize="sm">
                                                Visualiza cuales son nuestras zonas de entrega.
                                            </Text>
                                        </Box>
                                        <Text textAlign="center" w="10%">
                                            <FontAwesomeIcon icon="fas fa-chevron-right" size="xl" />
                                        </Text>
                                    </Flex>
                                </Box>
                            </Pressable>
                            <VMapModal
                                markersArray={companyCoordinatesArray}
                                dialogVisible={dialogVisible}
                                hideDialog={() => { setDialogVisible(false) }}
                            />
                        </VStack>

                        <Divider my="4" />

                        {renderGrid(company.products)}
                    </Box>
                    {renderModal()}
                    {renderOrderButton()}
                </Box>
            )
        }
    }
    
    return renderContent()
};



export default CompanyView;
